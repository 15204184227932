import React from "react"
import {StaticImage} from "gatsby-plugin-image";
import Layout from "../components/Layout/Layout";
import Logo from "../components/Logo/logo";

export default function Home() {
    return (
        <Layout>
            <div className={"container"}>
                <div className={"text-center"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"pb-5"}>
                                <Logo></Logo>
                            </div>
                            <p>Najdete na sociálních sítích</p>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-6 col-md-4 mb-4 text-center text-md-end order-md-1"}>
                            <a href="https://www.facebook.com/kutnohorak.cz/" target={"_blank"}>
                                <StaticImage src={"../assets/custom-style/images/fb.png"} alt={"Facebook"}
                                             placeholder="blurred"
                                             height={50}/>
                            </a>
                        </div>
                        <div className={"col-12 col-md-4 mb-4 text-center order-first order-md-2"}>
                            <a href={"https://www.youtube.com/channel/UCZyrgECIa8Yi9KvpOAynOnw"} target={"_blank"}>
                                <StaticImage src={"../assets/custom-style/images/yt.png"} alt={"Youtube"}
                                             placeholder="blurred"
                                             height={50}/>
                            </a>
                        </div>
                        <div className={"col-6 col-md-4 mb-4 text-center text-md-start order-md-last"}>
                            <a href={"https://www.instagram.com/kutnohorak.cz/"} target={"_blank"}>
                                <StaticImage src={"../assets/custom-style/images/ig.png"} alt={"Instagram"}
                                             placeholder="blurred"
                                             height={50}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    )
        ;
}